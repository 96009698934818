<template>
  <div class="home">
    <div class="landingSection">
      <div class="intro">
        <img class="meImg" src="../assets/Projekte/Ich/PBSquare.png">
        <div class="iconDiv">
          Contact me: <br>
          <a href="mailto:mc.haeusler@hotmail.com"><img class="icon" src="../assets/mail.png"></a>
          <a target="_blank" href="https://www.linkedin.com/in/marc-h%C3%A4usler-2b1547140/"><img class="icon" src="../assets/linkedin.png"></a>
        </div>
      </div>
        
      <div class="introduction">
        <h1>Hello there!</h1>
        I'm Marc Häusler, a Swiss ex-software developer and aspiring User Researcher & Experience Designer. I feel at home in the digial world and my goal is to make it accessible and understandable for everyone else too. <br><br> I'm passionate about learning from users experiences and creating new solutions for their needs.
        <button class="backToBtn topBtn" v-on:click="scrollToProjects">
          See projects
        </button>         
      </div>
      
    </div>

    <div class="content" ref="content">
      <h2>Bachelor Projects</h2>
      <div class="bachelorprojects snapper">
        <router-link to="/meso">
          <div class="project full" id="practical">          
            <img class="projectImage" src="../assets/Projekte/Bachelorarbeit/preview.png">
            <p class="projecttitle">MeSo - MEGA SOZIAL APP</p>
            <p class="projectShortDesc">Social platform for people with MS</p>
          </div>
        </router-link>
        
        <router-link to="/theorie">
          <div class="project case" id="theoretical">
            <img class="projectImage" src="../assets/Projekte/Bachelorarbeit/theoriePreview.png">
            <p class="projecttitle">CASE STUDY</p>
            <p class="projectShortDesc">Analysing the accessibility on Swiss websites</p>
          </div>
        </router-link>

      </div>

      <h2>Study Projects</h2>
      <div class="studyprojects snapper">    
        <router-link to="/info">
          <div class="project full" id="info">
            <img class="projectImage" src="../assets/Projekte/Infowerkstätte/preview.png">
            <p class="projecttitle">INFO-WERKSTÄTTEN</p>
            <p class="projectShortDesc">Navigation and support website for yearly event</p>
          </div>
        </router-link>

        <router-link to="/principles">
          <div class="project other" id="principle">
            <img class="projectImage" src="../assets/Projekte/PrincipleAnalysis/preview.png">
            <p class="projecttitle">DESIGN ANALYSIS</p>
            <p class="projectShortDesc">Analysing a previously made website with design principles</p>
          </div>
        </router-link>

        <router-link to="/searchfields">
          <div class="project case" id="searchfields">
            <img class="projectImage" src="../assets/Projekte/Suchfelder/preview.png">
            <p class="projecttitle">CASE STUDY</p>
            <p class="projectShortDesc">Building a UX test environment for a case study on search fields</p>
          </div>
        </router-link>

        <router-link to="/aerzte">
          <div class="project full" id="aerztehaus">
            <img class="projectImage" src="../assets/Projekte/Ärztehaus/preview.png">
            <p class="projecttitle">ÄRZTEHAUS VISCOSI</p>
            <p class="projectShortDesc">Online tool to schedule appointments for imaginary medical center</p>
          </div>
        </router-link>
      </div>

      <h2>UX thinking process</h2>
      <p class="description">
          In order to show how I would go about a simple UX task, I visualised my thinking process in a Miro board flowchart.
        </p>
      <div class="process snapper">     
        <a href="https://miro.com/app/board/o9J_l3yzikA=/" target="_blank">
          <div class="project other" id="thinking">
            <img class="projectImage" src="../assets/Projekte/Ich/designprocess.png">
            <p class="projecttitle">UX REDESIGN</p>
            <p class="projectShortDesc">My thinking process during a UX task</p>
          </div>
        </a>
      </div>        
    </div>
    <button class="backToBtn btmBtn" v-on:click="scrollToTop">
          back to top
    </button>
  </div>

  
</template>

<script>

export default {
  name: 'Home',
  methods: {
    scrollToTop: function (){
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    },
    scrollToProjects: function () {
      var element = this.$refs['content'];
      var topS = element.offsetTop;
      window.scrollTo({
        top: topS - 10,
        left: 0,
        behavior: 'smooth'
      });
    }
  },
  components: {
    
  }
}
</script>

<style scoped>
  a{
  text-decoration: none;
}

  
  .landingSection{
    height: 90vh;
    display: flex;
    flex-direction: column;
    margin: 0 15px 0;
  }

  .content{
    margin: 0 15px 0;
    display: flex;
    flex-direction: column;
  }

  .intro{
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    margin-right: -15px;
    margin-bottom: -5%;
    overflow: hidden;
  }
  
  .iconDiv {
    flex-grow: 1;
  }

  .icon{
    height: 30px;
    margin: 10px 10px 10px 0;
  }

  .meImg{
    width: 60%;
    display: inline;
    border-radius: 50%;
    margin-right: -12%;
    margin-top: -5%;
  }

  .description{
    margin-top:5px;
  }

  .project{
    height: 100%;
    min-height: 20vh;
    max-height: 50vh;
    width: 100%;
    
    margin-bottom: 10vw;
    background-color: lightgray;
    color: black;
  }

  .projectImage{
    max-height: 40vh;
    max-width: 100%;
    object-fit: cover;
    background-color: white;
  }

  .projecttitle{
    font-weight: 700;
    font-size: 130%;
    margin: 5px 0 5px;
    padding-left: 10px;
  }

  .projectShortDesc{
    font-weight: 400;
    font-size: 120%;
    margin: 0;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
  }


  .home {
    color: #000420;
    font-family: 'Karla';
  }

  /* project colors */
  #practical, #theoretical {
    background-color: #40798C;
    color:white;
  }

  .projectImage {
    background-color: #f5f7f4;
  }

  #info, #principle, #searchfields, #aerztehaus {
    background-color: #70A9A1;
  }

  #thinking{
    background-color: #9EC1A3;
    
  }

@media (min-width: 585px){
  .bachelorprojects a, .studyprojects a, .process a{
    width: calc(50% - 40px);
    margin: 10px 20px;
    height: 100%;
  }

  .landingSection{
    flex-direction: row;
    margin-top: 15%;
    height: 80vh;
  }

  .backToBtn{
    min-width: 10vw;
    display: block;
  }

  .topBtn{
    margin-top: 30px;
    min-width: 0;
  }

  .btmBtn {
    font-size: 150%;
  }

  .intro{
    flex-direction: column;
    align-items: center;
    margin-right: 0;
    margin-bottom: 0;
  }
  
  .iconDiv {
    min-width: 60%;
    margin-top: 20px;
  }
  .introduction{
    width: 60%;
    margin: 20px auto auto;
    font-size: 150%;
  }

  .meImg{
    width: 70%;
    border-radius: 50%;
    margin-right: 0;
    margin-top: 7%;
  }

  .description{
    width: 45%;
    font-size: 120%;
  }

  .bachelorprojects, .studyprojects, .process{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px
  }

  .project{
    height: auto;
    width: calc(100% - 25px);
    margin-bottom: 40px;
    margin-right: 0;
  }

  .projectShortDesc{
    min-height: 2.8em;
    line-height: 1.4em;
  }

  .home{
    scroll-snap-type: y mandatory;
  }
  
  .snapper{
    scroll-snap-align: start;
  }
}

</style>