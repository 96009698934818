<template>
  <div id="nav" v-bind:style="{ 'background-color': colorNav, 'color': colorNavText }">
    <p id="name">Marc Häusler</p>
    <router-link v-bind:style="{ 'color': colorNavText }" to="/">Projects</router-link> |
    <router-link v-bind:style="{ 'color': colorNavText }" to="/about">About me</router-link> | 
    <!-- <router-link to="/about">Resume</router-link> -->
    <a v-bind:style="{ 'color': colorNavText }"  href="../assets/Projekte/CV_Marc_Haeusler_English_Web.pdf" download="" target="_blank">Resume</a>
  </div>
  <router-view/>
</template>

<script>
export default {
    data() {
      return {
        colorNav: "#f5f7f4",
        colorNavText: "black"
      }
    },
    
  watch: {
    '$route' () {
      if (this.$route.path == '/meso' || this.$route.path == '/theorie') {
        this.colorNav = "#40798C"
        this.colorNavText = "white"
      }
      else if (this.$route.path == '/info' || this.$route.path == '/principles' || this.$route.path == '/searchfields' || this.$route.path == '/aerzte') {
        this.colorNav = "#70A9A1"
        this.colorNavText = "black"
      }
      else {
        this.colorNav = "#f5f7f4";
        this.colorNavText = "black"
      }
    }
  }
}
document.title = "Marc Häusler"
</script>

<style>

@font-face {
    font-family: "Karla";
    src: local("Karla"),   
    url("./assets/Fonts/Karla.ttf") format("truetype");
  }


body{
  margin: 0;
  /* background: #a83537; */
  color: #000420;
}
#name{
  font-family: 'Karla';
  font-size: 200%;
  margin: 0 0 5px;
}

.projectpage, .about{
  margin: 0 15px 0;
  font-family: 'Karla';
}


#app {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white;
}

#nav {
  padding: 15px;
  text-align: center;
  
}

#nav a {
  font-weight: bold;
  font-family: "Karla";
  font-weight: 500;
  text-underline-offset: 7px;
  text-decoration: none;
  color: rgb(71, 71, 71);
  
}

#nav a.router-link-exact-active {
  text-decoration: underline;
  text-decoration-color: rgb(71, 71, 71);
}

 /* Detailpages */
.projectpage{
  font-family: "Karla";
}

.projectpage h2{
  color: #70A9A1;
  margin-top: 30px;
}

.projectBigTitle{
  font-size: 1.5em;
  text-align: center;
}

.responsibilities, .keyfindings, .collaboration{
  font-family: monospace;
  font-size: 120%;
  margin-bottom: 30px;
}

.keyfindings{
  padding-left: 20px;
}

.keyfindings li{
  margin-top: 10px;
}

.previewImage{
  width: 100%;
  background: #f5f7f4;
  margin: auto;
}

.processImage {
  width: 100%;
  margin: auto;
}

.downloadDiv{
  font-size: 120%;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  background-color: rgb(245, 247, 244);
  border: solid black 1px;
  padding: 10px;
}

.downloadDiv p {
  margin: 0;
}

.downloadLink {
  color: #000420;
  text-decoration: none;
}

.material-icons-outlined.md-36 { 
  font-size: 36px;
}

.downloadFlex{
  display: inline-flex;
  min-width: 40vw;
  max-height: 3vh;
  align-items: center;
  background-color: rgb(245, 247, 244);
  border: solid black 1px;
  padding: 10px;
  margin-bottom: 20px;
}

.textDiv{
  font-size: 120%;
  font-weight: 600;
}

.backToBtn{
  display: block;
  min-width: 40vw;
  font-family: "Karla";
  font-size: 120%;
  font-weight: 600;
  margin-top: 10px;
  text-align: center;
  background-color: rgb(245, 247, 244);
  border: solid black 1px;
  padding: 10px;
}

.btmBtn{
  margin-bottom: 20px;
  float: right;
  margin-right: 15px;
}
.topBtn {
  margin-top: 20px;
}

/* About me page */

.about h2{
  margin-bottom: 5px;
}

@media (min-width: 585px){
  .home {
    width: 66vw;
    margin: auto;
  }

  .projectpage, .about{
    max-width: 720px;
    margin: auto;
    font-size: 120%;
  }

  .projectBackBtn {
    min-width: 0;
    margin-right: 33vw;
  }

  .downloadFlex{
    min-width: 0;
  }
}
</style>
